import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import commonReducer from 'src/modules/common/services/common.service';
import { adminRegulation } from 'src/modules/AdminRegulation/service/adminRegulation.service';
import { adminRegulationReviewed } from 'src/modules/AdminRegulation/service/adminRegulationLibrary.service';

const combinedReducer = combineReducers({
  common: commonReducer,
  adminRegulation: adminRegulation,
  adminRegulationReviewed:adminRegulationReviewed
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
